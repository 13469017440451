<template>
  <v-container fluid>
    <v-dialog
      v-model="dialog.show"
      max-width="600"
    >
      <template>
        <v-card>
          <v-card-title class="title primary white--text mb-4 text-capitalize">
            Email Details
            <v-spacer></v-spacer>
            <v-btn
              icon
              dark
              @click="dialog.show = false;"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text v-if="dialog.record.message">
            <p class="mt-4"><strong>To:</strong> {{dialog.record.to}}</p>
            <p><strong>Subject:</strong> {{dialog.record.message.subject}}</p>
            <p><strong>Body:</strong></p>
            <v-card
              color="#eeeeee"
              class="pa-4"
              flat
            >
              <div v-html='dialog.record.message.text'/>
            </v-card>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn
              text
              @click="dialog.show = false"
            >Close</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    <v-row wrap>
      <v-col cols="12" xs="12">
        <v-card class="elevation-0">
          <v-card-title
            class="pa-3 primary white--text font-weight-black headline"
          >
            <span class="ml-4">Email Log</span>
          </v-card-title>
          <v-card-text class="pt-4 px-1">
            <v-container fluid>
              <v-data-table
                :headers="headers"
                :items="emails"
                :items-per-page="30"
                :loading="loading"
                :search="search"
              >
                <template v-slot:top>
                  <v-text-field
                    v-model="search"
                    outlined
                    label="Search email log"
                    dense
                  ></v-text-field>
                </template>
                <template v-slot:item.createDate="{ item }">
                  {{ item.delivery.startTime.toDate() | moment('LLL') }}
                </template>
                <template v-slot:item.options="{ item }">
                <v-btn
                  outlined
                  text
                  small
                  :loading="dialog.loading"
                  :disabled="dialog.loading"
                  @click="showDialog(item)"
                  >
                  View
                  </v-btn>
                </template>
              </v-data-table>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import fb from "@/main";

  export default {
    data: () => ({
      loading: false,
      search: "",
      emails: [],
      dialog: {
        show: false,
        loading: false,
        record: {}
      },
      headers: [
        {
          text: "Create Date",
          value: "createDate",
        },
        {
          text: "To",
          value: "to",
        },
        {
          text: "Subject",
          value: "message.subject",
        },
        {
          text: "Status",
          value: "delivery.state",
        },
        {
          text: "",
          value: "options",
          align: "right",
        },
      ],
    }),
    mounted () {
      this.getData();
    },
    methods: {
      async showDialog (item) {
        this.dialog.loading = true;
        this.dialog.record = item;
        this.dialog.loading = false;
        this.dialog.show = true;
      },
      async getData() {
        try {
          this.loading = true;
          const getEmailLog = fb.db.collection("emails").orderBy('delivery.startTime', 'desc').limit(1000);
          const response = await getEmailLog.get();
          console.log("response", response)
          const data = response.docs.map(doc => {return {id: doc.id, ...doc.data()}});
          console.log('data', data)
          this.emails = data;
          this.loading = false;
        } catch (error) {
          console.log(error)
          this.loading = false;
        }
      }
    }
  }
</script>
