const getTrackingNumberObj = function(trackingNumber) {
    if (trackingNumber && trackingNumber.length) {
        const carriers = [
            {
                name: 'UPS',
                regex: /^1Z[0-9A-Z]{16}$/,
                url: 'https://www.ups.com/track?tracknum='
            },
            {
                name: 'FedEx',
                regex: /^[0-9]{12,14}$/,
                url: 'https://www.fedex.com/fedextrack/?tracknumbers='
            },
            {
                name: 'USPS',
                regex: /^([0-9]{20}|[0-9]{22}|[0-9]{26}|[A-Z]{2}[0-9]{9}US)$/,
                url: 'https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1='
            },
            // Add more carriers with their respective regex and tracking URL templates as needed
        ];

        for (let carrier of carriers) {
            if (carrier.regex.test(trackingNumber.toString())) {
                return {
                    carrier:carrier.name,
                    url: carrier.url + trackingNumber,
                    trackingNumber: trackingNumber.toString()
                }
            }
        }
    }

    return null; // No matching carrier found
}

const getEmailSignature = function() {
    let html = "";
    html += `<p style="color:#888888;">--<br>`;
    html += `Joe Casola<br>`;
    html += `Saints Title<br>`;
    html += `Saints Engine and Machine<br>`;
    html += `614-886-9544<br>`;
    html += `<a href="https://www.saintsengine.com">www.saintsengine.com</a><br>`;
    html += `<a href="mailto:title@saintsengine.com">title@saintsengine.com</a></p>`;
    return html;
}

export {
    getTrackingNumberObj,
    getEmailSignature
}