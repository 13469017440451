import Vue from "vue";
import Router from "vue-router";
import fb from '@/main';
import store from "./store";

import Home from "@/views/Home.vue";
import Emails from "@/views/Emails.vue";
import Login from "./views/Login.vue";
import Verification from "./views/Verification.vue";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
      meta: { requiresAuth: true }
    },
    {
      path: "/emails",
      name: "emails",
      component: Emails,
      meta: { requiresAuth: true }
    },
    {
      path: "/verification",
      name: "verification",
      component: Verification,
    },
    {
      path: "/login",
      name: "login",
      component: Login
    }
  ]
});
router.beforeEach(async (to, from, next) => {
  // Check for requiresAuth guard
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // Check if NO logged userd
    if (!fb.auth.currentUser) {
      next({
        path: '/login'
      });
    } else {
      if(store.getters['user']){
        // don't need to fetch user, already cached
        if(!fb.auth.currentUser.emailVerified){
          next({
            path: '/verification'
          });
        } else {
          next()
        }
      } else {
        if(!fb.auth.currentUser.emailVerified){
          next({
            path: '/verification'
          });
        } else {
          await store.dispatch('getUser')
          next();
        }
      }
    }
  } else {
    // Proceed to route
    next()
  }
});
export default router;
